import React from "react";
import AdmissionForm from "../components/AdmissionForm";

const Admission = () => {
  return (
    <div className="flex flex-col w-full h-[100vh] overflow-y-auto">
      <section className="bg-slate-50">
        <div className="w-full flex justify-between items-center px-10  font-bold text-center text-2xl py-5">
          <img
            src="https://www.mindcoders.in/assets/img/mindcoderlogo.webp"
            alt="mindcoders"
            className="h-14"
          />
          <h1 className="text-3xl bg-blue-950 hidden md:block py-1 px-6 rounded-md text-white">
            Students Admission Form
          </h1>
          <img className="h-14" src="./assets/msmelogo.png" alt="msme" />
        </div>
      </section>
      <hr />
      <section className="w-full">
      <h1 className="text-xl text-center block md:hidden mx-5 mt-5 bg-blue-950 rounded-md text-white">
            Students Admission Form
          </h1>
        <div className="mx-5 my-5 md:my-10 p-6 bg-white border rounded-lg relative">
          <AdmissionForm />
        </div>
      </section>
    </div>
  );
};

export default Admission;
