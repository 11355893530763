import React, { useCallback, useRef, useState } from "react";
import { FaCamera, FaFileImage } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdCameraswitch } from "react-icons/md";
import Webcam from "react-webcam";

const WebcamComponent = ({ className, height, width, getImage,imageSrc,setImageSrc, webCamEnabled, setWebCamEnabled }) => {
  
  const webcamRef = useRef(null);
 
  const [facingMode, setFacingMode] = useState("user");
  // const [imageSrc, setImageSrc] = useState(null);

  const switchCamera = useCallback(() => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  }, []);

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot();
    setImageSrc(image);

    if (getImage) {
      getImage(image);
    }
  }, [webcamRef, getImage, setImageSrc]);

  const videoConstraints = {
    width: width,
    height: height,
    facingMode: facingMode,
  };

  return (
    <>
      <div className="items-center flex justify-center w-full p-4 border rounded-lg mb-2">
        {imageSrc === null && webCamEnabled ? (
          <Webcam
            audio={false}
            mirrored={facingMode === "user"}
            height={height}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={width}
            className={`h-[${height}px]`}
            videoConstraints={videoConstraints}
          />
        ) : imageSrc ? (
          <img
            src={imageSrc}
            alt="user"
            height={height}
            width={width}
            loading="lazy"
          />
        ) : (
          <FaFileImage className="h-[12rem] text-gray-600 w-[12rem]" />
        )}
      </div>
      <div className="flex justify-around items-center">
        {webCamEnabled ? (
          <>
            {imageSrc === null && (
              <MdCameraswitch
                onClick={switchCamera}
                className="text-green-600 text-4xl rounded-lg bg-slate-100 border p-2 cursor-pointer"
              />
            )}
            {imageSrc === null && (
              <FaCamera
                onClick={capture}
                className="text-gray-600 text-4xl rounded-lg bg-slate-100 border p-2 cursor-pointer"
              />
            )}
            <ImCross
              onClick={() => {
                setWebCamEnabled(false);
                setImageSrc(null);
              }}
              className="text-red-600 text-4xl rounded-lg bg-slate-100 border p-2 cursor-pointer"
            />
          </>
        ) : (
          <FaCamera
            onClick={() => setWebCamEnabled(true)}
            className="text-gray-600 text-4xl rounded-lg bg-slate-100 border p-2 cursor-pointer"
          />
        )}
      </div>
    </>
  );
};

export default WebcamComponent;
